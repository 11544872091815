import { DeckingSpecification } from "./specification"
import { Model } from "./model"

export enum StepName {
  SIZE = "SIZE",
  HEIGHT = "HEIGHT",
  BOARDS = "BOARDS",
  FASCIAS = "FASCIAS",
  BOM = "BOM",
  SAVE_PLAN = "SAVE_PLAN",
  PLACE_ORDER = "PLACE_ORDER",
  COMPLETE = "COMPLETE",
}

export type WizardStepProps = {
  step: StepName
  spec: DeckingSpecification
  deckModel: Model
  reverse?: boolean
  isActive?: boolean
  isVisible: boolean
  doNotRenderUnlessVisible?: boolean
  onBack: () => void
  onConfirm: (pathname?: StepName) => void
  onUpdateSpec: (spec: DeckingSpecification) => void
}
