import createReduxHelpersForCollection, {
  CollectionState,
} from "infrastructure/reduxCollection"
import { createSelector } from "reselect"
import { MerchantDeckingConfiguration } from "types/firebase"
import { baseSpec } from "data/specification"
import { BaseSpecification } from "types"
import { Dispatch } from "redux"
import { ReduxState } from "./createStore"

const collectionName = "merchantDeckingConfiguration"

const {
  reducer,
  fetchActionCreator,
  patchActionCreator,
  selectAllDocuments,
  selectDocumentById,
  selectDocumentStateById,
} =
  createReduxHelpersForCollection<MerchantDeckingConfiguration>(collectionName)

export type MerchantDeckingConfigurationState =
  CollectionState<MerchantDeckingConfiguration>

const fetchLatestMerchantConfiguration =
  (merchantId: string) =>
  async (dispatch: Dispatch, getState, { db }) => {
    const merchantRef = db.collection("merchants").doc(merchantId)
    const merchantConfiguration = await db
      .collection("merchantDeckingConfiguration")
      .where("merchant", "==", merchantRef)
      .orderBy("insertedAt", "desc")
      .limit(1)
      .get()
      .then((result) => {
        if (!result.empty) {
          const data = result.docs[0].data()
          const payload = {
            id: result.docs[0].id,
            merchantId: merchantId,
            wizardSettings: data.wizardSettings,
            defaultSpecification: {
              ...baseSpec,
              ...data.defaultSpecification,
            },
            appSettings: data.appSettings,
          }

          dispatch(fetchActionCreator([payload]))

          return payload
        }
      })

    return merchantConfiguration
  }

const updateDefaultSpecification =
  (
    merchantId: string,
    updates: Partial<BaseSpecification>,
    saveToApi = false
  ) =>
  (dispatch, getState, { db }) => {
    const state = getState()
    const oldConfig = selectDocumentById(state, merchantId)
    const updatedDeckingSpecification: MerchantDeckingConfiguration = {
      ...oldConfig,
      defaultSpecification: { ...oldConfig.defaultSpecification, ...updates },
    }

    if (saveToApi) {
      db.collection("merchantDeckingConfiguration")
        .doc(merchantId)
        .update(updatedDeckingSpecification)
        .then(() => {
          dispatch(patchActionCreator(updatedDeckingSpecification, true))
        })
        .catch(() => {
          console.log("error")
        })
    } else {
      return dispatch(patchActionCreator(updatedDeckingSpecification))
    }
  }

const selectMerchantConfiguration = createSelector(
  (state: ReduxState) => state.appSettings.merchantId,
  (state: ReduxState) => state.merchantDeckingConfiguration.data,
  (merchantId, merchantConfigurations) =>
    merchantConfigurations[merchantId]?.document
)

export {
  reducer,
  fetchLatestMerchantConfiguration,
  updateDefaultSpecification,
  selectAllDocuments,
  selectDocumentById,
  selectDocumentStateById,
  selectMerchantConfiguration,
}
