import {
  allFeaturesVisible,
  allPartsVisible,
} from "components/Wizard/visibilityHelpers"
import { DeckingSpecification, Model, StepName, Margins } from "types"
import { fetchLatestMerchantConfiguration } from "./merchantDeckingConfiguration"
import defaultDeckingSpecification from "data/specification"
import simpleModel from "models/simpleModel"

const INITIALIZE_WIZARD = "wizard/initialise"
const UPDATE_WIZARD = "wizard/update_specification"
const UPDATE_WIZARD_STEP = "wizard/update_step"

export type WizardState = {
  step: StepName
  margin: Margins
  model: Model
  spec: DeckingSpecification
  originalSpec: DeckingSpecification
}

const initializeWizard = (merchantId) => async (dispatch, getState) => {
  const state = getState()
  if (state.wizard.spec) {
    return
  }

  const merchantConfiguration = await dispatch(
    fetchLatestMerchantConfiguration(merchantId)
  )
  const { defaultSpecification } = merchantConfiguration

  const spec: DeckingSpecification = {
    ...defaultSpecification,
    visibility: {
      ...allPartsVisible,
      ...allFeaturesVisible,
    },
    materials: {
      ...defaultDeckingSpecification.materials,
    },
    fixings: {
      ...defaultDeckingSpecification.fixings,
    },
  }

  return dispatch({
    type: INITIALIZE_WIZARD,
    payload: {
      spec,
      model: simpleModel(spec),
    },
  })
}

const updateWizard =
  (specUpdates: Partial<DeckingSpecification>) => (dispatch, getState) => {
    const state = getState()
    const newSpec = { ...state.wizard.spec, ...specUpdates }
    const model = simpleModel(newSpec)

    dispatch({
      type: UPDATE_WIZARD,
      payload: {
        spec: newSpec,
        model,
      },
    })
  }

const updateWizardStep = (step) => ({
  type: UPDATE_WIZARD_STEP,
  payload: {
    step,
  },
})

const getInitialState = (): WizardState => {
  return {
    margin: { top: 200, bottom: 200, left: 200, right: 400 },
    step: StepName.SIZE,
    spec: null,
    model: null,
    originalSpec: null,
  }
}

const reducer = (
  state: WizardState = getInitialState(),
  action
): WizardState => {
  switch (action.type) {
    case INITIALIZE_WIZARD: {
      return {
        margin: { top: 200, bottom: 200, left: 200, right: 400 },
        step: StepName.SIZE,
        spec: action.payload.spec,
        model: action.payload.model,
        originalSpec: action.payload.spec,
      }
    }
    case UPDATE_WIZARD: {
      return {
        ...state,
        model: action.payload.model,
        spec: action.payload.spec,
      }
    }

    case UPDATE_WIZARD_STEP: {
      return {
        ...state,
        step: action.payload.step,
      }
    }
  }

  return state
}

export { reducer, initializeWizard, updateWizard, updateWizardStep }
