import { Alert, Title, Close } from "@zendeskgarden/react-notifications"
import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "redux/createStore"
import { first } from "lodash/fp"
import { popNotificationActionCreator } from "redux/notifications"

export default function () {
  const dispatch = useAppDispatch()

  const notifications = useSelector(
    (state: RootState) => state.notifications.notifications
  )
  const handleClose = () => dispatch(popNotificationActionCreator())
  const lastNotification = first(notifications)

  return lastNotification ? (
    <Alert type={lastNotification.type}>
      <Title>{lastNotification.title}</Title>
      {lastNotification.description}
      <Close onClick={handleClose} />
    </Alert>
  ) : null
}
