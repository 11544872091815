import { DeckingSpecification, Metadata } from "types"

const getMetadata = (spec: DeckingSpecification): Metadata => {
  const joistWidth = spec.materials.Joist.width
  const fasciaWidth = spec.materials.DeckingBoard.height
  const ledgerBoardOffset = spec.addLedgerBoards ? joistWidth : 0
  const rimJoistOffset = spec.addRimJoists ? joistWidth : 0

  const fasciaOffset = {
    left: spec.addFascias.left ? fasciaWidth : 0,
    right: spec.addFascias.right ? fasciaWidth : 0,
    top: spec.addFascias.top ? fasciaWidth : 0,
    bottom: spec.addFascias.bottom ? fasciaWidth : 0,
  }

  const meta: Metadata = {
    joistWidth: joistWidth,
    postWidth: spec.materials.Post.width,
    fasciaWidth,

    fasciaOffset,
    ledgerBoardOffset,
    rimJoistOffset,
    outsideDeckWidth:
      spec.width -
      fasciaOffset.left -
      fasciaOffset.right -
      ledgerBoardOffset * 2,
    outsideDeckLength:
      spec.length - fasciaOffset.top - fasciaOffset.bottom - rimJoistOffset * 2,

    postRowSpacing: [],
    postColumnSpacing: [],
    fixingsRequired: [],
  }

  return meta
}

export default getMetadata
