import { DEFAULT_THEME, PALETTE } from "@zendeskgarden/react-theming"
import { Theme } from "types/theme"
import Color from "color"

const white = "white"
const green = PALETTE.green[600]
const black = PALETTE.grey[800]
const grey = PALETTE.grey[600]
const selected = PALETTE.yellow[600]
const brown = PALETTE.yellow[800]

const adminTheme: Theme = {
  ...DEFAULT_THEME,
  page: {
    background: white,
    border: PALETTE.grey[300],
  },
  inputs: {
    color: black,
    border: Color(green).darken(0.1),
    background: Color(white).lighten(0.35),
    borderActive: Color(green).darken(0.1),
    backgroundActive: Color(white).lighten(0.3),
  },
  diagram: {
    background: white,
    grid: {
      stroke: Color(green).alpha(0.2),
    },
    dimensions: {
      stroke: Color(black).alpha(0.75),
    },
    ground: {
      fill: Color(grey).alpha(0.5),
      stroke: Color(grey).alpha(0.5),
    },
    selected: {
      stroke: black,
      fill: Color(selected),
    },
    post: {
      stroke: black,
      fill: Color(brown).darken(0.25).alpha(0.8),
    },
    beam: {
      stroke: black,
      fill: Color(brown).lighten(0.2).alpha(0.75),
    },
    joist: {
      stroke: black,
      fill: Color(brown).lighten(0.15).alpha(0.65),
    },
    noggin: {
      stroke: black,
      fill: Color(brown).lighten(0.15).alpha(0.5),
    },
    ledgerBoard: {
      stroke: black,
      fill: Color(brown).lighten(0.15).alpha(0.5),
    },
    deckingBoard: {
      stroke: black,
      fill: Color(brown).darken(0.1).alpha(0.75),
    },
  },
}

export default adminTheme
