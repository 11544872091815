import { filter, pipe, uniqBy } from "lodash/fp"
import {
  ModelledPart,
  FixingType,
  PartType,
  HelperFunctionProps,
  BillOfMaterialsItem,
} from "../../types"
import {
  getPartElevation,
  addFixingRequirements,
  placePartsForSpans,
} from "../helpers"

const addLedgerBoards = ({
  parts,
  billOfMaterials,
  metadata,
  spec,
}: HelperFunctionProps) => {
  const { length: deckLength } = spec
  const {
    joistWidth,
    postColumnSpacing,
    postRowSpacing,
    outsideDeckLength,
    fasciaOffset,
    rimJoistOffset,
  } = metadata

  const offset = fasciaOffset.top

  const commonJoistProps = {
    type: PartType.LedgerBoard,
    material: spec.materials.Joist,
    color: "black",
    visible: spec.visibility.Joist,
    length: outsideDeckLength + rimJoistOffset * 2,
    zPosition: getPartElevation(PartType.LedgerBoard, spec),
  }

  let fixingsRequired = metadata.fixingsRequired
  const joistRows = pipe(
    filter((part: ModelledPart) => part.type === PartType.Joist),
    uniqBy("yPosition")
  )(parts)
  const addLedgerBoardSpan = ({ xPosition, yPosition }) => {
    ledgerBoardSpans.push({
      ...commonJoistProps,
      xPosition,
      yPosition,
    })

    fixingsRequired = addFixingRequirements(fixingsRequired, [
      {
        fixingType: FixingType.LedgerBoardToPost,
        quantity: postRowSpacing.length + 1,
      },
      {
        fixingType: FixingType.LedgerBoardToJoist,
        quantity: joistRows.length,
      },
    ])
  }

  // determine where beams are required
  const ledgerBoardSpans = []
  postColumnSpacing.forEach(({ outside }, index) => {
    if (index === 0) {
      addLedgerBoardSpan({
        xPosition: outside.start - joistWidth,
        yPosition: offset,
      })
    }

    if (index === postColumnSpacing.length - 1) {
      addLedgerBoardSpan({
        xPosition: outside.end,
        yPosition: offset,
      })
    }
  })

  const { partsPlaced: joists, materialQuantityUsed } = placePartsForSpans(
    ledgerBoardSpans,
    {
      isVertical: true,
      material: spec.materials.Joist,
    }
  )

  const billOfMaterialsItem: BillOfMaterialsItem = {
    description: "Ledger boards",
    material: spec.materials.Joist,
    color: "black",
    purchaseQuantity: materialQuantityUsed,
    totalPurchasePrice: spec.materials.Joist.price * materialQuantityUsed,
  }

  return {
    parts: [...parts, ...joists],
    billOfMaterials: [...billOfMaterials, billOfMaterialsItem],
    metadata: { ...metadata, fixingsRequired },
    spec,
  }
}

export default addLedgerBoards
