import React from "react"
import { AppSettings } from "types"

const AppSettingsContext = React.createContext<AppSettings>({
  baseUrl: "/",
  merchantId: null,
  onAddToCart: () => {},
  onEventLogged: () => {},
  onPlanSaved: () => {},
})

export const { Provider, Consumer } = AppSettingsContext
export default AppSettingsContext
