import { filter, omit, pipe, reduce, values } from "lodash/fp"
import { createSelector } from "reselect"
import { Action } from "redux"

const LIST = "firestore/LIST/"
const CREATE = "firestore/CREATE/"
const PATCH = "firestore/PATCH/"
const DELETE = "firestore/DELETE/"

const fetchActionType = (name: string) => `${LIST}${name}`
const fetchErrorActionType = (name: string) => `${LIST}${name}/error`
const createActionType = (name: string) => `${CREATE}${name}`
const patchActionType = (name: string) => `${PATCH}${name}`
const deleteActionType = (name: string) => `${DELETE}${name}`

interface Document {
  id: string
}

function ApiError(message) {
  this.status = this.name = "MyError"
  this.message = message
  this.stack = new Error().stack
}
export interface ApiError {
  status: number
  name: string
  code: string
  message: string
  stack?: []
}

export interface DocumentState<T extends Document> {
  hasLoadedOnce: boolean
  isLoading: boolean
  isUpdating: boolean
  isDirty: boolean
  error: ApiError | null
  document: T | null
  savedDocument: T | null
}

export interface CollectionState<T extends Document> {
  hasLoadedOnce: boolean
  data: Record<string, DocumentState<T>>
}

interface ListAction<T> {
  type: string
  payload: {
    data: Array<T>
  }
}

interface InstanceAction<T> {
  type: string
  payload: {
    document: T
    saved?: boolean
  }
}

interface ErrorAction<T> {
  type: string
  payload: {
    documentId
    error: ApiError
  }
}

const getCompleteDocumentState = <T extends Document>(
  updates: Partial<DocumentState<T>>
): DocumentState<T> => ({
  hasLoadedOnce: true,
  isLoading: false,
  isUpdating: false,
  isDirty: false,
  error: null,
  savedDocument: null,
  document: null,
  ...updates,
})

const getInitialState = <T extends Document>(): CollectionState<T> => ({
  hasLoadedOnce: false,
  data: {},
})

const createReducerForCollection =
  <T extends Document>(collection: string) =>
  (state: CollectionState<T> = getInitialState<T>(), action: Action) => {
    switch (action.type) {
      case fetchActionType(collection): {
        const data = (action as ListAction<T>).payload.data
        return {
          hasLoadedOnce: true,
          data: reduce(
            (newState: CollectionState<T>, document: T) => {
              newState[document.id] = getCompleteDocumentState({
                document,
                savedDocument: document,
              })
              return newState
            },
            { ...state.data }
          )(data),
        }
      }

      case fetchErrorActionType(collection): {
        const { documentId, error } = (action as ErrorAction<T>).payload
        return {
          hasLoadedOnce: state.hasLoadedOnce,
          data: {
            ...state.data,
            [documentId]: getCompleteDocumentState<T>({
              hasLoadedOnce: false,
              ...state.data[documentId],
              error,
            }),
          },
        }
      }

      case createActionType(collection): {
        const payload = (action as InstanceAction<T>).payload
        const document = payload.document
        return {
          hasLoadedOnce: state.hasLoadedOnce,
          data: {
            ...state.data,
            [document.id]: getCompleteDocumentState({
              document,
              savedDocument: payload.saved ? document : null,
              isDirty: !payload.saved,
            }),
          },
        }
      }

      case patchActionType(collection): {
        const payload = (action as InstanceAction<T>).payload
        const document = payload.document
        const oldDocument = state.data[document.id]?.document
        const updatedDocument = { ...oldDocument, ...document }
        return {
          data: {
            ...state.data,
            [document.id]: getCompleteDocumentState({
              document: updatedDocument,
              savedDocument: payload.saved ? updatedDocument : oldDocument,
              isDirty: !payload.saved,
            }),
          },
        }
      }

      case deleteActionType(collection): {
        const document = (action as InstanceAction<T>).payload.document
        return {
          data: omit(document.id)(state.data),
        }
      }
    }

    return state
  }

const curryListActionCreator =
  <T extends Document>(actionType: string) =>
  (data: Array<T>): ListAction<T> => ({
    type: actionType,
    payload: {
      data,
    },
  })

const curryInstanceActionCreator =
  <T extends Document>(actionType: string) =>
  (document: T, saved = false): InstanceAction<T> => ({
    type: actionType,
    payload: {
      document,
      saved,
    },
  })

const curryErrorActionCreator =
  <T extends Document>(actionType: string) =>
  (documentId: string, error: ApiError): ErrorAction<T> => ({
    type: actionType,
    payload: {
      documentId,
      error,
    },
  })

const createCollectionSelector =
  <T extends Document>(collectionName) =>
  (state): CollectionState<T> =>
    state[collectionName]

const createReduxHelpersForCollection = <T extends Document>(
  collectionName: string
) => {
  const selectCollection = createCollectionSelector<T>(collectionName)

  const selectDocumentStateById = createSelector(
    (state, merchantId) => merchantId,
    selectCollection,
    (id, collection): DocumentState<T> =>
      collection.data[id] || {
        hasLoadedOnce: false,
        isLoading: false,
        isUpdating: false,
        isDirty: false,
        document: null,
        error: null,
        savedDocument: null,
      }
  )

  const selectDocumentById = createSelector(
    (state, documentId) => documentId,
    selectCollection,
    (id, collection: CollectionState<T>): T | null =>
      collection.data[id]?.document
  )

  const selectAllDocuments = createSelector(
    selectCollection,
    (collection: CollectionState<T>): Array<T> =>
      pipe(
        values,
        filter(({ hasLoadedOnce }) => hasLoadedOnce)
      )(collection.data)
  )

  return {
    reducer: createReducerForCollection<T>(collectionName),
    selectCollection,
    selectAllDocuments,
    selectDocumentStateById,
    selectDocumentById,
    fetchActionCreator: curryListActionCreator<T>(
      fetchActionType(collectionName)
    ),
    fetchErrorActionCreator: curryErrorActionCreator<T>(
      fetchErrorActionType(collectionName)
    ),
    createActionCreator: curryInstanceActionCreator<T>(
      createActionType(collectionName)
    ),
    patchActionCreator: curryInstanceActionCreator<T>(
      patchActionType(collectionName)
    ),
    deleteActionCreator: curryInstanceActionCreator<T>(
      deleteActionType(collectionName)
    ),
  }
}

export default createReduxHelpersForCollection
