import { DeckingSpecification, PartType } from "types"

export const groundDepthRequired = (height) => {
  return 100 + Math.ceil(height / 300) * 100
}

export const getPartElevation = (
  type: PartType,
  spec: DeckingSpecification
) => {
  switch (type) {
    case PartType.DeckingBoard:
      return spec.height
    case PartType.Joist:
      return spec.height - spec.materials.DeckingBoard.height
    case PartType.LedgerBoard:
      return spec.height - spec.materials.DeckingBoard.height
    case PartType.Beam:
      return (
        spec.height -
        spec.materials.Joist.height -
        spec.materials.DeckingBoard.height
      )
    case PartType.Post:
      return spec.height - spec.materials.DeckingBoard.height
    case PartType.Fascia:
      return spec.height
  }
}
