import { HelperFunctionProps } from "../../types"
import getMetadata from "./getMetadata"
import getPostSpacing from "./getPostSpacing"
import placePartsForSpans from "./placePartsFromSpans"

const noop = (props: HelperFunctionProps) => props

export * from "./getElevation"
export * from "./fixings"
export { noop, getMetadata, getPostSpacing, placePartsForSpans }
