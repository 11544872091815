import React, { Suspense } from "react"
import PageWithNav from "components/Layout/PageWithNav"
import { RouteComponentProps } from "react-router"

const LoadablePlanner = React.lazy(
  () => import(/* webpackChunkName: "planner" */ "./Planner")
)

const PlannerLayout = ({ match }: RouteComponentProps<{ planId: string }>) => {
  const planId = match.params?.planId
  return (
    <PageWithNav>
      <Suspense fallback={<h1>Loading</h1>}>
        <LoadablePlanner planId={planId} />
      </Suspense>
    </PageWithNav>
  )
}

export default PlannerLayout
