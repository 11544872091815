import { DeckingSpecification, Metadata, Model, ModelType } from "types"
import { pipe } from "lodash/fp"
import {
  getMetadata,
  getPostSpacing,
  getFixingBomItemsFromRequirements,
  noop,
} from "./helpers"
import {
  addPosts,
  addBeams,
  addLedgerBoards,
  addJoists,
  addDecking,
  addFascias,
} from "./parts"

const generate = (spec: DeckingSpecification): Model => {
  const meta = getMetadata(spec)
  const { parts, billOfMaterials, metadata } = pipe(
    getPostSpacing,
    addPosts,
    spec.addBeams ? addBeams : noop,
    addJoists,
    spec.addLedgerBoards ? addLedgerBoards : noop,
    addDecking,
    addFascias
  )({ parts: [], billOfMaterials: [], spec, metadata: meta })

  const fixingsBomItems = getFixingBomItemsFromRequirements(
    metadata.fixingsRequired,
    spec
  )

  return {
    type: ModelType.Deck,
    spec,
    parts,
    billOfMaterials: [...billOfMaterials, ...fixingsBomItems],
  }
}

export default generate
