import {
  BillOfMaterialsItem,
  FixingType,
  HelperFunctionProps,
  PartType,
} from "types"
import {
  groundDepthRequired,
  getPartElevation,
  addFixingRequirements,
} from "../helpers"

const addPosts = ({
  parts,
  billOfMaterials,
  metadata,
  spec,
}: HelperFunctionProps) => {
  const { postRowSpacing, postColumnSpacing } = metadata
  const groundDepth = groundDepthRequired(spec.height)
  const postLength = getPartElevation(PartType.Post, spec) + groundDepth

  let fixingsRequired = metadata.fixingsRequired
  const commonPostProps = {
    type: PartType.Post,
    material: spec.materials.Post,
    length: postLength,
    color: "black",
    visible: spec.visibility.Post,
    zPosition: postLength - groundDepth,
  }
  const posts = []
  const addPost = ({ xPosition, yPosition }) => {
    posts.push({
      ...commonPostProps,
      xPosition,
      yPosition,
    })

    fixingsRequired = addFixingRequirements(fixingsRequired, [
      {
        fixingType: FixingType.PostToGround,
        quantity: 1,
      },
    ])
  }

  postRowSpacing.forEach((rowSpacing, rowIndex) => {
    const isLastRow = rowIndex === postRowSpacing.length - 1
    postColumnSpacing.forEach((columnSpacing, columnIndex) => {
      const isLastColumn = columnIndex === postColumnSpacing.length - 1

      addPost({
        ...commonPostProps,
        xPosition: columnSpacing.outside.start,
        yPosition: rowSpacing.outside.start,
      })

      if (isLastColumn) {
        addPost({
          ...commonPostProps,
          xPosition: columnSpacing.inside.end,
          yPosition: rowSpacing.outside.start,
        })
      }

      if (isLastRow) {
        addPost({
          ...commonPostProps,
          xPosition: columnSpacing.outside.start,
          yPosition: rowSpacing.inside.end,
        })
      }

      if (isLastColumn && isLastRow) {
        addPost({
          ...commonPostProps,
          xPosition: columnSpacing.inside.end,
          yPosition: rowSpacing.inside.end,
        })
      }
    })
  })

  const postsPerMaterialLength = Math.floor(
    spec.materials.Post.length / postLength
  )
  const materialQuantityRequired = Math.ceil(
    posts.length / postsPerMaterialLength
  )

  const billOfMaterialsItem: BillOfMaterialsItem = {
    description: "Posts",
    material: spec.materials.Post,
    color: "black",
    purchaseQuantity: materialQuantityRequired,
    totalPurchasePrice: spec.materials.Post.price * materialQuantityRequired,
  }

  return {
    parts: [...parts, ...posts],
    billOfMaterials: [...billOfMaterials, billOfMaterialsItem],
    metadata: { ...metadata, fixingsRequired },
    spec,
  }
}

export default addPosts
