import { useEffect } from "react"
import { Provider } from "react-redux"
import { HelmetProvider } from "react-helmet-async"
import { loadAuthUser, clearAuth } from "redux/auth"
import { ThemeProvider } from "@zendeskgarden/react-theming"
import adminTheme from "themes/admin"

const BaseApp = ({ children, store, auth }) => {
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseAuthUser) => {
      if (firebaseAuthUser) {
        store.dispatch(loadAuthUser(firebaseAuthUser))
      } else {
        store.dispatch(clearAuth())
      }
    })

    return () => {
      store.dispatch(clearAuth())
      unsubscribe()
    }
  }, [auth, store])

  return (
    <ThemeProvider theme={adminTheme}>
      <HelmetProvider>
        <Provider store={store}>{children}</Provider>
      </HelmetProvider>
    </ThemeProvider>
  )
}

export default BaseApp
