import { filter, last, pipe, sortBy, uniqBy } from "lodash/fp"
import {
  ModelledPart,
  PartType,
  HelperFunctionProps,
  BillOfMaterialsItem,
  FixingType,
} from "types"
import { addFixingRequirements, getPartElevation } from "../helpers"

const addDecking = ({
  parts,
  billOfMaterials,
  spec,
  metadata,
}: HelperFunctionProps): HelperFunctionProps => {
  const { width: deckWidth, length: deckLength } = spec
  const { joistWidth, fasciaOffset } = metadata

  const xOffset = fasciaOffset.left
  const yOffset = fasciaOffset.top
  const insideDeckLength = deckLength - fasciaOffset.top - fasciaOffset.bottom
  const insideDeckWidth = deckWidth - fasciaOffset.right - fasciaOffset.left

  const material = spec.materials.DeckingBoard
  const boardWidth = material.width
  const boardLength = material.length

  const boards: ModelledPart[] = []

  let fixingsRequired = metadata.fixingsRequired
  let coveredWidth = 0
  let coveredLength = 0
  let remainingLength = 0
  let quantityUsed = 0

  const joistRows = pipe(
    filter((part: ModelledPart) => part.type === PartType.Joist),
    uniqBy("yPosition"),
    sortBy("yPosition")
  )(parts)

  const boardProperties = {
    type: PartType.DeckingBoard,
    material,
    color: "black",
    visible: spec.visibility.DeckingBoard,
    zPosition: getPartElevation(PartType.DeckingBoard, spec),
  }

  const consumeMaterial = () => {
    quantityUsed++
    remainingLength = boardLength
  }

  const getJoistsToCover = filter(
    ({ yPosition }) =>
      yPosition + joistWidth / 2 >= coveredLength &&
      yPosition + joistWidth / 2 <= coveredLength + remainingLength
  )

  while (coveredWidth < insideDeckWidth) {
    while (coveredLength <= insideDeckLength) {
      if (remainingLength < spec.maxJoistSpacing) {
        consumeMaterial()
      }

      const joistsToCover = getJoistsToCover(joistRows)
      const lastJoistToCover = last(joistsToCover)
      const lastJoist = last(joistRows)

      const lastCoveredPosition =
        lastJoistToCover?.yPosition || insideDeckLength
      const lengthToNextJoistOrDeckEnd =
        lastCoveredPosition + joistWidth < lastJoist.yPosition + joistWidth
          ? lastCoveredPosition + joistWidth / 2 - coveredLength - yOffset
          : insideDeckLength - coveredLength
      const lengthOfBoardUsed = Math.min(
        remainingLength,
        lengthToNextJoistOrDeckEnd
      )

      const nextBoard = {
        ...boardProperties,
        xPosition: xOffset + coveredWidth,
        yPosition: yOffset + coveredLength,
        length: lengthOfBoardUsed,
        width: Math.min(
          boardProperties.material.width,
          insideDeckWidth - coveredWidth
        ),
      }

      if (nextBoard.width > 5) {
        boards.push(nextBoard)

        const numberOfJoistsFixingsRequired = Math.min(
          joistRows.length,
          joistsToCover.length +
            (coveredLength + lengthOfBoardUsed >= insideDeckLength ? 1 : 0)
        )

        fixingsRequired = addFixingRequirements(fixingsRequired, [
          {
            fixingType: FixingType.DeckingToJoistFixing,
            quantity: numberOfJoistsFixingsRequired,
          },
        ])
      }

      coveredLength =
        coveredLength + lengthOfBoardUsed + spec.spaceBetweenDeckingBoardLength
      remainingLength = remainingLength - lengthOfBoardUsed
    }

    coveredLength = 0
    coveredWidth += boardWidth + spec.spaceBetweenDeckingBoardWidths
  }

  const billOfMaterialsItem: BillOfMaterialsItem = {
    description: "Decking boards",
    material,
    color: "black",
    purchaseQuantity: quantityUsed,
    totalPurchasePrice: material.price * quantityUsed,
  }

  return {
    parts: [...parts, ...boards],
    billOfMaterials: [...billOfMaterials, billOfMaterialsItem],
    spec,
    metadata: { ...metadata, fixingsRequired },
  }
}

export default addDecking
