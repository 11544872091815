import "@zendeskgarden/css-bedrock"

import ReactDOM from "react-dom"
import reportWebVitals from "infrastructure/reportWebVitals"
import { auth, db, GoogleAuthProvider } from "infrastructure/firebase"
import { AppSettings } from "types/appSettings"

import App from "./App"

function init(appSettings: AppSettings & { rootElementId: string }) {
  ReactDOM.render(
    <App
      appSettings={appSettings}
      auth={auth}
      db={db}
      GoogleAuthProvider={GoogleAuthProvider}
    />,
    document.getElementById(appSettings.rootElementId)
  )
}

reportWebVitals()

export { init }
