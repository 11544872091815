import { HelperFunctionProps } from "../../types"

const getPostSpacing = ({
  parts,
  billOfMaterials,
  spec,
  metadata,
}: HelperFunctionProps): HelperFunctionProps => {
  const { length: deckLength, width: deckWidth } = spec
  const { postWidth, joistWidth } = metadata

  const fasciaHeight = spec.materials.DeckingBoard.height
  const fascias = spec.addFascias

  const rowOffset = spec.addRimJoists ? joistWidth : 0
  const topOffset = rowOffset + (fascias.top ? fasciaHeight : 0)
  const bottomOffset = rowOffset + (fascias.bottom ? fasciaHeight : 0)

  const lengthForRows = deckLength - postWidth - (topOffset + bottomOffset)
  const numberOfRows = Math.ceil(lengthForRows / spec.maxPostSpacing)
  const rowSpacing = lengthForRows / numberOfRows

  const postRowSpacing = []
  for (let row = 0; row < numberOfRows; row++) {
    postRowSpacing.push({
      spacing: rowSpacing,
      rimOffset: rowOffset,
      outside: {
        start: topOffset + rowSpacing * row,
        end: topOffset + rowSpacing * (row + 1) + postWidth,
      },
      inside: {
        start: topOffset + rowSpacing * row + postWidth,
        end: topOffset + rowSpacing * (row + 1),
      },
    })
  }

  const columnOffset =
    spec.addLedgerBoards || spec.pairBeamsAroundExteriorPosts ? joistWidth : 0
  const leftOffset = columnOffset + (fascias.left ? fasciaHeight : 0)
  const rightOffset = columnOffset + (fascias.right ? fasciaHeight : 0)

  const widthForColumns = deckWidth - postWidth - (leftOffset + rightOffset)
  const numberOfColumns = Math.ceil(widthForColumns / spec.maxPostSpacing)
  const columnSpacing = widthForColumns / numberOfColumns
  const postColumnSpacing = []

  for (let column = 0; column < numberOfColumns; column++) {
    postColumnSpacing.push({
      spacing: columnSpacing,
      rimOffset: leftOffset,
      outside: {
        start: leftOffset + columnSpacing * column,
        end: leftOffset + columnSpacing * (column + 1) + postWidth,
      },
      inside: {
        start: leftOffset + columnSpacing * column + postWidth,
        end: leftOffset + columnSpacing * (column + 1),
      },
    })
  }

  return {
    parts,
    billOfMaterials,
    metadata: {
      ...metadata,
      postColumnSpacing,
      postRowSpacing,
    },
    spec,
  }
}

export default getPostSpacing
