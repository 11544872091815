import {
  PartType,
  HelperFunctionProps,
  DeckingSpecification,
  FixingType,
  ModelledPart,
  BillOfMaterialsItem,
} from "types"
import { pipe, filter, sortBy, uniqBy } from "lodash/fp"
import {
  getPartElevation,
  addFixingRequirements,
  placePartsForSpans,
} from "../helpers"
import { findSupports } from "models/helpers/placePartsFromSpans"

const shouldAddJoistAfterFirstPost = (
  isFirstRow: boolean,
  { addRimJoists, pairJoistsAroundExteriorPosts }: DeckingSpecification
) => {
  if (isFirstRow && addRimJoists) return pairJoistsAroundExteriorPosts
  return true
}

const shouldAddJoistBeforeNextPost = (
  isLastRow: boolean,
  {
    addRimJoists,
    pairJoistsAroundPosts,
    pairJoistsAroundExteriorPosts,
  }: DeckingSpecification
) => {
  if (isLastRow && addRimJoists && pairJoistsAroundExteriorPosts) return true
  if (isLastRow && !addRimJoists) return true
  if (!isLastRow && pairJoistsAroundPosts) return true
  return false
}

const addJoists = ({
  parts,
  billOfMaterials,
  metadata,
  spec,
}: HelperFunctionProps) => {
  const {
    joistWidth,
    postRowSpacing,
    fasciaOffset,
    ledgerBoardOffset,
    outsideDeckWidth,
  } = metadata
  const xPosition = fasciaOffset.left + ledgerBoardOffset
  const length = outsideDeckWidth

  const commonJoistProps = {
    type: PartType.Joist,
    material: spec.materials.Joist,
    color: "black",
    visible: spec.visibility.Joist,
    length,
    xPosition,
    zPosition: getPartElevation(PartType.Joist, spec),
  }

  const numberOfBeamSpans = pipe(
    filter((part: ModelledPart) => part.type === PartType.Beam),
    uniqBy("xPosition"),
    (spans) => spans.length
  )(parts)

  let fixingsRequired = metadata.fixingsRequired
  const addJoistSpan = ({ yPosition, fixToBeam }) => {
    joistSpans.push({
      ...commonJoistProps,
      yPosition,
    })

    fixingsRequired = addFixingRequirements(fixingsRequired, [
      {
        fixingType: fixToBeam
          ? FixingType.JoistToBeamFixing
          : FixingType.JoistToPostFixing,
        quantity: fixToBeam ? numberOfBeamSpans : postRowSpacing.length + 1,
      },
    ])
  }

  // determine where beams are required
  const joistSpans = []
  postRowSpacing.forEach(({ inside, outside }, index) => {
    const isFirstRow = index === 0
    const isLastRow = index === postRowSpacing.length - 1

    const addJoistAfterFirstPost = shouldAddJoistAfterFirstPost(
      isFirstRow,
      spec
    )
    const addJoistBeforeNextPost = shouldAddJoistBeforeNextPost(isLastRow, spec)

    // add start and end joists
    if (addJoistAfterFirstPost) {
      addJoistSpan({
        yPosition: inside.start,
        fixToBeam: false,
      })
    }

    if (addJoistBeforeNextPost) {
      addJoistSpan({
        yPosition: inside.end - joistWidth,
        fixToBeam: false,
      })
    }

    // add intermediate joists
    const firstJoistYPosition = addJoistAfterFirstPost
      ? inside.start + joistWidth
      : outside.start
    const lastJoistYPosition = addJoistBeforeNextPost
      ? inside.end - joistWidth
      : outside.end
    const spaceBetween = lastJoistYPosition - firstJoistYPosition
    const numberOfIntermediateJoists =
      Math.ceil(spaceBetween / spec.maxJoistSpacing) - 1

    if (numberOfIntermediateJoists > 0) {
      const joistSpacing = spaceBetween / (numberOfIntermediateJoists + 1)

      for (let i = 0; i < numberOfIntermediateJoists; i++) {
        addJoistSpan({
          yPosition:
            firstJoistYPosition + joistSpacing * (i + 1) - joistWidth / 2,
          fixToBeam: true,
        })
      }
    }

    // rim joists
    if (spec.addRimJoists && isFirstRow) {
      addJoistSpan({
        yPosition: outside.start - joistWidth,
        fixToBeam: false,
      })
    }

    if (spec.addRimJoists && isLastRow) {
      addJoistSpan({
        ...commonJoistProps,
        yPosition: outside.end,
        fixToBeam: false,
      })
    }
  })

  const supports = findSupports({
    isVertical: true,
    partType: PartType.Beam,
    supportSpanLength: outsideDeckWidth,
  })(parts)
  const { partsPlaced: joists, materialQuantityUsed } = placePartsForSpans(
    sortBy("yPosition")(joistSpans),
    {
      isVertical: false,
      supports,
      material: spec.materials.Joist,
      allowEndJoinery: false,
    }
  )

  const billOfMaterialsItem: BillOfMaterialsItem = {
    description: "Joists",
    material: spec.materials.Joist,
    color: "black",
    purchaseQuantity: materialQuantityUsed,
    totalPurchasePrice: spec.materials.Joist.price * materialQuantityUsed,
  }

  return {
    parts: [...parts, ...joists],
    billOfMaterials: [...billOfMaterials, billOfMaterialsItem],
    metadata: { ...metadata, fixingsRequired },
    spec,
  }
}

export default addJoists
