import { AppUser, AuthUser } from "types"

const AUTH_CLEAR = "auth/clearAuthentication"
const AUTH_START = "auth/startAuthenticating"
const AUTH_LOAD_AUTH_USER = "auth/loadAuthUser"
const AUTH_LOAD_CURRENT_USER = "auth/currentUser"

export type AuthError = {
  status: number
  name: string
  message: string
}

export type AuthState = {
  loading: boolean
  error?: AuthError
  currentUser: AppUser
  authUser: AuthUser
}

const transformAuth = (firebaseAuth): AuthUser => ({
  uid: firebaseAuth.uid,
  displayName: firebaseAuth.displayName,
  email: firebaseAuth.email,
  emailVerified: firebaseAuth.emailVerified,
  phoneNumber: firebaseAuth.phoneNumber,
})

export const getInitialState = (): AuthState => {
  return {
    loading: false,
    error: null,
    currentUser: null,
    authUser: null,
  }
}

export const clearAuth = (error?: {
  status: number
  name: string
  message: string
}) => ({
  type: AUTH_CLEAR,
  payload: {
    error,
  },
})

const startAuthentication = () => ({
  type: AUTH_START,
})

export const loadAuthUser = (authUser) => ({
  type: AUTH_LOAD_AUTH_USER,
  payload: {
    authUser: transformAuth(authUser),
  },
})

export const loadCurrentUser = (currentUser: AppUser) => ({
  type: AUTH_LOAD_CURRENT_USER,
  payload: {
    currentUser,
  },
})

const reducer = (state: AuthState = getInitialState(), action): AuthState => {
  switch (action.type) {
    case AUTH_CLEAR: {
      return action.payload.error
        ? { ...getInitialState(), error: action.payload.error }
        : getInitialState()
    }
    case AUTH_START: {
      return { ...state, loading: true }
    }
    case AUTH_LOAD_AUTH_USER: {
      return { ...state, authUser: action.payload.authUser, loading: true }
    }
    case AUTH_LOAD_CURRENT_USER: {
      const currentUser = action.payload.currentUser
      if (state.authUser && state.authUser.uid === currentUser.id) {
        return { ...state, currentUser: currentUser, loading: false }
      } else {
        return getInitialState()
      }
    }
  }

  return state
}

export const fetchCurrentUser =
  (authId: string) =>
  async (dispatch, getState, { db }) => {
    return db
      .collection("users")
      .doc(authId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          dispatch(
            loadCurrentUser({
              id: authId,
              ...doc.data(),
            })
          )
        } else {
          dispatch(
            clearAuth({
              status: 404,
              name: "Not found",
              message:
                "We could not find a deckingcalculator.app user associated with this google account",
            })
          )
        }
      })
      .catch((err) => {
        console.log("clearAuth - error loading current user")
        dispatch(
          clearAuth({
            status: err.status || 500,
            name: err.name || "Error",
            message:
              err.message ||
              "An unknown error occurred while we tried to get the deckingcalculator.app user associated with this account",
          })
        )
      })
  }

const authenticateWithGoogle =
  () =>
  async (dispatch, getState, { auth, GoogleAuthProvider }) => {
    const authProvider = new GoogleAuthProvider()
    dispatch(startAuthentication())
    return auth
      .signInWithPopup(authProvider)
      .then((authData) => {
        return transformAuth(authData?.additionalUserInfo?.profile)
      })
      .catch((err) => {
        dispatch(
          clearAuth({
            status: err.status || 500,
            name: err.name || "Error",
            message:
              err.message ||
              "An unknown error occurred while we tried to get to log in using a google account",
          })
        )
      })
  }

const signOutFromFirebase =
  () =>
  (dispatch, getState, { auth }) => {
    auth.signOut()
  }

export { reducer, signOutFromFirebase, authenticateWithGoogle }
