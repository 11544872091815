import {
  BaseSpecification,
  BillOfMaterialsItem,
  Material,
  FixingSpecification,
} from "types/specification"
import { PartType, FixingType } from "types/enums"
import { StepName } from "./wizard"

export type AuthUser = {
  uid: string
  displayName: string
  email: string
  emailVerified: boolean
  phoneNumber: string
}

export interface AppUser {
  id: string
  name: string
  email: string
  roles: [string]
  merchant: string
}

export interface Merchant {
  id: string
  name: string
}

export interface MerchantDeckingConfiguration {
  id: string
  merchantId: string
  appSettings: any
  wizardSettings: {
    steps: Array<StepName>
  }
  defaultSpecification: BaseSpecification
}

export interface Plan {
  id: string
  authId: string
  name: string
  merchantId: string
  merchant: any
  orderedAt: Date
  specification: BaseSpecification
  fixings?: Record<FixingType, Array<FixingSpecification>>
  materials?: {
    [PartType.Joist]: Material
    [PartType.DeckingBoard]: Material
    [PartType.Post]: Material
  }
  billOfMaterials?: BillOfMaterialsItem[]
}
