import { useSelector } from "react-redux"
import {
  authenticateWithGoogle,
  signOutFromFirebase,
  AuthState,
} from "redux/auth"
import { useAppDispatch } from "redux/createStore"

const useFirebaseAuth = () => {
  const dispatch = useAppDispatch()
  const { error, loading, authUser, currentUser } = useSelector(
    (state: any) => state.auth
  ) as AuthState

  const loginWithGoogle = () => {
    return dispatch(authenticateWithGoogle())
  }

  const logout = () => {
    dispatch(signOutFromFirebase())
  }

  return {
    authUser,
    currentUser,
    error,
    isLoading: loading,
    isMerchant: currentUser?.roles.includes("MERCHANT"),
    isAdmin: currentUser?.roles.includes("ADMIN"),
    logout,
    loginWithGoogle,
  }
}

export default useFirebaseAuth
