import Color from "color"
import { DEFAULT_THEME } from "@zendeskgarden/react-theming"
import { Theme } from "types/theme"

const createTheme = (): Theme => {
  const black = "#2a2a2a"
  const grey = "#999"
  const green = "#a8c69e"
  const white = "#fefefe"
  const brown = "#664326"
  const blue = "#a3dadf"
  // const red = "#e37472"
  const parts = brown

  return {
    ...DEFAULT_THEME,
    font: {
      baseFontSize: 16,
      primary: black,
      secondary: grey,
      headingFontFamily: "Helvetica, sans-serif",
      bodyFontFamily: "Helvetica, sans-serif",
    },
    page: {
      background: white,
      border: Color(green).darken(0.1),
    },
    header: {
      color: white,
      background: green,
      border: Color(green).darken(0.2),
    },
    buttons: {
      primary: {
        padding: "0.75rem 1.5rem",
        color: white,
        border: `1px solid ${Color(green).darken(0.3)}`,
        background: Color(green).darken(0.3),
        borderActive: `1px solid ${Color(green).darken(0.4)}`,
        backgroundActive: Color(green).darken(0.4),
      },
      secondary: {
        padding: "0.75rem 1.5rem",
        color: black,
        border: "1px solid transparent",
        background: "transparent",
        borderActive: `1px solid ${Color(green).darken(0.3)}`,
        backgroundActive: white,
      },
      copyPaste: {
        padding: "0.75rem 1.5rem",
        color: black,
        border: `1px solid ${Color(green).darken(0)}`,
        background: Color(grey).lighten(0.4),
        borderActive: `1px solid ${Color(green).darken(0.2)}`,
        backgroundActive: Color(grey).lighten(0.2),
      },
    },
    inputs: {
      color: black,
      border: Color(green).darken(0.1),
      background: Color(white).lighten(0.35),
      borderActive: Color(green).darken(0.1),
      backgroundActive: Color(white).lighten(0.3),
    },
    diagram: {
      background: white,
      grid: {
        stroke: Color(green).alpha(0.5),
      },
      dimensions: {
        stroke: Color(black).alpha(0.75),
      },
      ground: {
        fill: Color(grey).alpha(0.5),
        stroke: Color(grey).alpha(0.5),
      },
      selected: {
        stroke: black,
        fill: Color(blue),
      },
      post: {
        stroke: black,
        fill: Color(parts).darken(0.25).alpha(0.8),
      },
      beam: {
        stroke: black,
        fill: Color(parts).lighten(0.2).alpha(0.75),
      },
      joist: {
        stroke: black,
        fill: Color(parts).lighten(0.15).alpha(0.65),
      },
      noggin: {
        stroke: black,
        fill: Color(parts).lighten(0.15).alpha(0.5),
      },
      ledgerBoard: {
        stroke: black,
        fill: Color(parts).lighten(0.15).alpha(0.5),
      },
      deckingBoard: {
        stroke: black,
        fill: Color(brown).darken(0.1).alpha(0.75),
      },
    },
  }
}

export default createTheme()
