import { pipe } from "lodash/fp"
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript"

import {
  FixingType,
  PartType,
  HelperFunctionProps,
  BillOfMaterialsItem,
  ModelledPart,
} from "../../types"
import {
  getPartElevation,
  addFixingRequirements,
  placePartsForSpans,
} from "../helpers"

const addFascias = ({
  parts,
  billOfMaterials,
  metadata,
  spec,
}: HelperFunctionProps) => {
  const { width: deckWidth, length: deckLength } = spec
  const { postRowSpacing, postColumnSpacing, fasciaWidth } = metadata

  const horizontalFasciaSpans: ModelledPart[] = []
  const verticalFasciaSpans: ModelledPart[] = []
  let fixingsRequired = metadata.fixingsRequired

  const commonBeamProps = {
    type: PartType.Fascia,
    material: spec.materials.DeckingBoard,
    color: "black",
    visible: spec.visibility.Fascia,
    zPosition: getPartElevation(PartType.Fascia, spec),
  }

  const addFasciaSpan = (options: {
    length: number
    xPosition: number
    yPosition: number
    xyRotation: number
  }) => {
    if (options.xyRotation === 0) {
      verticalFasciaSpans.push({
        ...commonBeamProps,
        ...options,
      })
    } else {
      horizontalFasciaSpans.push({
        ...commonBeamProps,
        ...options,
      })
    }

    fixingsRequired = addFixingRequirements(fixingsRequired, [
      {
        fixingType: FixingType.FasciaToPostFixing,
        quantity:
          options.xyRotation === 90
            ? postRowSpacing.length + 1
            : postColumnSpacing.length + 1,
      },
    ])
  }

  const {
    left: hasLeft,
    right: hasRight,
    top: hasTop,
    bottom: hasBottom,
  } = spec.addFascias

  const leftOffset = metadata.fasciaOffset.left
  const rightOffset = metadata.fasciaOffset.right

  if (hasLeft) {
    addFasciaSpan({
      xPosition: 0,
      yPosition: 0,
      xyRotation: 0,
      length: deckLength,
    })
  }

  if (hasRight) {
    addFasciaSpan({
      xPosition: deckWidth - fasciaWidth,
      yPosition: 0,
      xyRotation: 0,
      length: deckLength,
    })
  }

  if (hasTop) {
    addFasciaSpan({
      xPosition: leftOffset,
      yPosition: 0,
      xyRotation: 90,
      length: deckWidth - leftOffset - rightOffset,
    })
  }

  if (hasBottom) {
    addFasciaSpan({
      xPosition: leftOffset,
      yPosition: deckLength - fasciaWidth,
      xyRotation: 270,
      length: deckWidth - leftOffset - rightOffset,
    })
  }

  const { partsPlaced: fascias, materialQuantityUsed } = pipe(
    () =>
      placePartsForSpans(verticalFasciaSpans, {
        isVertical: true,
        material: spec.materials.DeckingBoard,
      }),
    (metadata) => {
      return placePartsForSpans(horizontalFasciaSpans, {
        ...metadata,
        isVertical: false,
      })
    }
  )()

  const billOfMaterialsItem: BillOfMaterialsItem = {
    description: "Fascias",
    material: spec.materials.DeckingBoard,
    color: "black",
    purchaseQuantity: materialQuantityUsed,
    totalPurchasePrice:
      spec.materials.DeckingBoard.price * materialQuantityUsed,
  }

  return {
    parts: [...parts, ...fascias],
    billOfMaterials: [...billOfMaterials, billOfMaterialsItem],
    metadata: { ...metadata, fixingsRequired },
    spec,
  }
}

export default addFascias
