import { DeckingBoard, Joist, Post } from "./materials"
import { CoachBolt, CoachScrew, Postcrete, Screw } from "./fixing"
import {
  DeckingSpecification,
  BaseSpecification,
  Direction,
  PartType,
  FixingType,
  DiagramFeature,
} from "types"

const baseSpec: BaseSpecification = {
  width: 2400,
  length: 3600,
  height: 300,
  maxJoistSpacing: 500,
  maxPostSpacing: 1000,
  maxNogginSpacing: 1500,

  spaceBetweenDeckingBoardLength: 3,
  spaceBetweenDeckingBoardWidths: 1,

  boardDirection: Direction.Vertical,

  addRimJoists: true,
  addLedgerBoards: true,
  pairJoistsAroundExteriorPosts: false,
  pairJoistsAroundPosts: false,
  allowEndJoinery: false,

  addBeams: true,
  pairBeamsAroundPosts: false,
  pairBeamsAroundExteriorPosts: false,

  addFascias: {
    top: false,
    left: false,
    right: false,
    bottom: false,
  },
}

const spec: DeckingSpecification = {
  ...baseSpec,

  materials: {
    [PartType.Joist]: Joist,
    [PartType.Post]: Post,
    [PartType.DeckingBoard]: DeckingBoard,
  },

  fixings: {
    [FixingType.BeamToPostFixing]: [{ fixing: CoachBolt, quantity: 1 }],
    [FixingType.JoistToPostFixing]: [{ fixing: CoachBolt, quantity: 1 }],
    [FixingType.JoistToJoistEndFixing]: [{ fixing: Screw, quantity: 8 }],
    [FixingType.BeamToBeamEndFixing]: [{ fixing: Screw, quantity: 8 }],
    [FixingType.FasciaToPostFixing]: [{ fixing: Screw, quantity: 2 }],
    [FixingType.JoistToBeamFixing]: [{ fixing: CoachScrew, quantity: 1 }],
    [FixingType.LedgerBoardToJoist]: [{ fixing: CoachScrew, quantity: 2 }],
    [FixingType.LedgerBoardToPost]: [{ fixing: CoachScrew, quantity: 2 }],
    [FixingType.DeckingToJoistFixing]: [{ fixing: Screw, quantity: 2 }],
    [FixingType.PostToGround]: [{ fixing: Postcrete, quantity: 1 }],
  },

  visibility: {
    [PartType.Post]: true,
    [PartType.Beam]: true,
    [PartType.Joist]: true,
    [PartType.DeckingBoard]: true,
    [DiagramFeature.TopView]: true,
    [DiagramFeature.FrontElevation]: true,
  },
}

export { baseSpec }
export default spec
