export enum ModelType {
  Deck = "Deck",
}

export enum PartType {
  DeckingBoard = "DeckingBoard",
  Fascia = "Fascia",
  Joist = "Joist",
  LedgerBoard = "LedgerBoard",
  Beam = "Beam",
  Post = "Post",
  Noggin = "Noggin",
}

export enum FixingType {
  PostToGround = "PostToGround",
  DeckingToJoistFixing = "DeckingToJoistFixing",
  LedgerBoardToJoist = "LedgerBoardToJoist",
  LedgerBoardToPost = "LedgerBoardToPost",
  JoistToPostFixing = "JoistToPostFixing",
  JoistToJoistEndFixing = "JoistToJoistFixing",
  JoistToBeamFixing = "JoistToBeamFixing",
  BeamToPostFixing = "BeamToPostFixing",
  BeamToBeamEndFixing = "BeamToBeamFixing",
  FasciaToPostFixing = "FasciaToPostFixing",
}

export enum Direction {
  Horizontal = "horizontal",
  Vertical = "vertical",
}

export enum DiagramFeature {
  TopView = "TopView",
  FrontElevation = "FrontElevation",
  FasciaSelectors = "FasciaSelectors",
  BannisterSelectors = "BannisterSelectors",
}
