import {
  DeckingSpecification,
  FixingType,
  PartType,
  HelperFunctionProps,
  BillOfMaterialsItem,
  ModelledPart,
} from "types"
import {
  getPartElevation,
  addFixingRequirements,
  placePartsForSpans,
} from "../helpers"

const shouldAddBeamBeforeFirstPost = (
  isFirstRow: boolean,
  { pairBeamsAroundExteriorPosts }: DeckingSpecification
) => {
  if (isFirstRow) return pairBeamsAroundExteriorPosts
  return false
}

const shouldAddBeamAfterFirstPost = (isFirstColumn, spec) => {
  return true
}

const shouldAddBeamAfterLastPost = (
  isLastRow: boolean,
  { pairBeamsAroundExteriorPosts }: DeckingSpecification
) => {
  if (isLastRow) return pairBeamsAroundExteriorPosts
  return false
}

const shouldAddBeamBeforeLastPost = (
  isLastRow: boolean,
  { pairBeamsAroundPosts }: DeckingSpecification
) => {
  if (isLastRow) return true
  return pairBeamsAroundPosts
}

const addBeams = ({
  parts,
  billOfMaterials,
  metadata,
  spec,
}: HelperFunctionProps) => {
  const {
    joistWidth,
    postColumnSpacing,
    postRowSpacing,
    outsideDeckLength,
    fasciaOffset,
    rimJoistOffset,
  } = metadata

  const beamSpans = []
  let fixingsRequired = metadata.fixingsRequired

  const commonBeamProps: Omit<ModelledPart, "xPosition"> = {
    type: PartType.Beam,
    material: spec.materials.Joist,
    color: "black",
    visible: spec.visibility.Beam,
    length: outsideDeckLength + rimJoistOffset * 2,
    yPosition: fasciaOffset.top,
    zPosition: getPartElevation(PartType.Beam, spec),
  }

  const addBeamSpan = ({ xPosition }) => {
    beamSpans.push({
      xPosition,
      ...commonBeamProps,
    })

    fixingsRequired = addFixingRequirements(fixingsRequired, [
      {
        fixingType: FixingType.BeamToPostFixing,
        quantity: postRowSpacing.length + 1,
      },
    ])
  }

  // determine where beams are required
  postColumnSpacing.forEach(({ inside, outside }, index) => {
    const isFirstColumn = index === 0
    const isLastColumn = index === postColumnSpacing.length - 1

    if (shouldAddBeamBeforeFirstPost(isFirstColumn, spec)) {
      addBeamSpan({
        xPosition: outside.start - joistWidth,
      })
    }

    if (shouldAddBeamAfterFirstPost(isFirstColumn, spec)) {
      addBeamSpan({
        xPosition: inside.start,
      })
    }

    if (shouldAddBeamBeforeLastPost(isLastColumn, spec)) {
      addBeamSpan({
        xPosition: inside.end - joistWidth,
      })
    }

    if (shouldAddBeamAfterLastPost(isLastColumn, spec)) {
      addBeamSpan({
        xPosition: outside.end,
      })
    }
  })

  const { partsPlaced: beams, materialQuantityUsed } = placePartsForSpans(
    beamSpans,
    {
      isVertical: true,
      material: spec.materials.Joist,
    }
  )

  const billOfMaterialsItem: BillOfMaterialsItem = {
    description: "Beams",
    material: spec.materials.Joist,
    color: "black",
    purchaseQuantity: materialQuantityUsed,
    totalPurchasePrice: spec.materials.Joist.price * materialQuantityUsed,
  }

  return {
    parts: [...parts, ...beams],
    billOfMaterials: [...billOfMaterials, billOfMaterialsItem],
    metadata: { ...metadata, fixingsRequired },
    spec,
  }
}

export default addBeams
