import { AppDispatch } from "redux/createStore"

const PUSH_NOTIFICATION = "notifications/push"
const POP_NOTIFICATION = "notifications/pop"
const CLEAR_NOTIFICATIONS = "notifications/clear"

export enum NotificationType {
  INFO = "info",
  WARNING = "warning",
  ERROR = "error",
  SUCCESS = "success",
}

export type Notification = {
  title: string
  type: NotificationType
  description: string
}

const pushNotificationActionCreator = (notification: Notification) => ({
  type: PUSH_NOTIFICATION,
  payload: notification,
})

export const popNotificationActionCreator = () => ({
  type: POP_NOTIFICATION,
})

export const pushNotification =
  (notification: Notification) => (dispatch: AppDispatch) => {
    dispatch(pushNotificationActionCreator(notification))
  }

export type NotificationsState = {
  notifications: Notification[]
}

const getInitialState = (): NotificationsState => {
  return {
    notifications: [],
  }
}

export const reducer = (
  state: NotificationsState = getInitialState(),
  action
): NotificationsState => {
  switch (action.type) {
    case PUSH_NOTIFICATION: {
      return { notifications: [...state.notifications, action.payload] }
    }
    case POP_NOTIFICATION: {
      return {
        notifications: state.notifications.slice(1),
      }
    }
    case CLEAR_NOTIFICATIONS: {
      return getInitialState()
    }
  }

  return state
}
