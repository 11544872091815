import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
} from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import {
  reducer as merchantDeckingConfiguration,
  MerchantDeckingConfigurationState,
} from "./merchantDeckingConfiguration"
import { reducer as authReducer, AuthState } from "./auth"
import { reducer as planReducer, PlanReducerState } from "./plans"
import { reducer as wizardReducer, WizardState } from "./wizard"
import { reducer as appSettingsReducer, AppSettingsState } from "./appSettings"
import {
  reducer as notificationsReducer,
  NotificationsState,
} from "./notifications"
import { useDispatch } from "react-redux"

export type ReduxState = {
  appSettings: AppSettingsState
  auth: AuthState
  merchantDeckingConfiguration: MerchantDeckingConfigurationState
  plans: PlanReducerState
  wizard: WizardState
}

type CreateStoreOptions = {
  initialState?: Partial<ReduxState>
  auth
  db
  GoogleAuthProvider
}

export const createStore = ({
  initialState = {},
  auth,
  ...rest
}: CreateStoreOptions) => {
  const rootReducer = combineReducers({
    appSettings: appSettingsReducer,
    auth: authReducer,
    merchantDeckingConfiguration,
    wizard: wizardReducer,
    plans: planReducer,
    notifications: notificationsReducer,
  })

  const composedEnhancer = composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({ auth, ...rest }))
  )

  const store = reduxCreateStore(rootReducer, initialState, composedEnhancer)

  return { store, auth }
}

// build fake store to get proper types without initialising auth
const { store: storeForTypes } = createStore({
  initialState: {},
  auth: null,
  db: null,
  GoogleAuthProvider: null,
})

// infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof storeForTypes.getState>
// inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof storeForTypes.dispatch
export const useAppDispatch = (): AppDispatch => useDispatch()

export default createStore
