import React from "react"
import { ThemeProvider } from "styled-components"
import styled from "styled-components"
import { Chrome, Body } from "@zendeskgarden/react-chrome"

import Navigation from "./PublicNav"
import architect from "themes/architect"
import Notifications from "components/Notifications"

const FloatingNotificationWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`

const PageWithNav = ({ children }: { children: React.ReactChild }) => {
  return (
    <ThemeProvider theme={architect}>
      <Chrome isFluid>
        <Navigation />
        <Body>{children}</Body>
        <FloatingNotificationWrapper>
          <Notifications />
        </FloatingNotificationWrapper>
      </Chrome>
    </ThemeProvider>
  )
}

export default PageWithNav
