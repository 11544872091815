import { filter, last, pipe, uniqBy, map } from "lodash/fp"
import { Material, ModelledPart, PartType } from "../../types"

export const findSupports =
  ({
    partType,
    isVertical,
    supportSpanLength,
  }: {
    partType: PartType
    isVertical: boolean
    supportSpanLength: number
  }) =>
  (supportingParts: Array<ModelledPart>): Array<ModelledPart> =>
    pipe(
      filter((p) => p.type === partType),
      uniqBy(isVertical ? "xPosition" : "yPosition"),
      map((part) => ({
        ...part,
        length: supportSpanLength,
      }))
    )(supportingParts)

const getSupportCentre = (
  support: ModelledPart,
  isSupportVertical: boolean
) => {
  const supportStart = isSupportVertical ? support.xPosition : support.yPosition
  return supportStart + support.material.width / 2
}

export const findBestPartLengthBasedOnSupports = ({
  start,
  maximumPartLength,
  supports,
  isVertical,

  allowEndJoinery = false,
  endJoineryLength = 300,
  endSpacing = 3,
}: {
  start: number
  maximumPartLength: number
  isVertical: boolean
  supports: Array<ModelledPart>

  allowEndJoinery?: boolean
  endJoineryLength?: number
  endSpacing?: number
}) => {
  const areSupportsVertical = !isVertical
  const maxEndOfThisPart = start + maximumPartLength

  const supportsCovered = filter((support: ModelledPart) => {
    const supportCentre = getSupportCentre(support, areSupportsVertical)
    return supportCentre <= start + maximumPartLength + endSpacing
  })(supports)

  const lastSupportCovered = last(supportsCovered)
  const lastSupportIndex = lastSupportCovered
    ? supports.indexOf(lastSupportCovered)
    : 0
  const nextSupportIndex = lastSupportIndex + 1
  const hasNextSupport = nextSupportIndex < supports.length

  // If the part overhangs a support, by a smaller amount than than the join size
  // cut at that support
  if (lastSupportCovered && hasNextSupport) {
    const lastSupportCentre = getSupportCentre(
      lastSupportCovered,
      areSupportsVertical
    )
    const overhangAfterCoveredSupport = maxEndOfThisPart - lastSupportCentre
    if (
      !allowEndJoinery ||
      overhangAfterCoveredSupport < endJoineryLength / 2
    ) {
      return lastSupportCentre - start
    }
  }

  // If the overhang is too close to the next support for a join, cut at the previous support
  const nextSupportCentreOrEnd = hasNextSupport
    ? getSupportCentre(supports[nextSupportIndex], areSupportsVertical)
    : start + maximumPartLength
  const distanceToNextSupportCentreOrEnd =
    nextSupportCentreOrEnd - (start + maximumPartLength)
  if (distanceToNextSupportCentreOrEnd < endJoineryLength) {
    return nextSupportCentreOrEnd - start
  }

  // There is enough space to do a join, so end at max
  return maximumPartLength
}

type PlacePartsRecursivelyMeta = {
  isVertical: boolean
  material: Material
  materialLength: number
  materialLengthAvailable: number
  materialQuantityUsed: number
  partsPlaced: Array<ModelledPart>
  supports: Array<ModelledPart>

  minimumUsableLength: number
  allowEndJoinery: boolean
  endJoineryLength: number
}

export const placePartForSpan = (
  span: ModelledPart,
  meta: PlacePartsRecursivelyMeta
) => {
  const {
    isVertical,
    materialQuantityUsed,
    materialLengthAvailable: oldLengthAvailable,
    materialLength,
    minimumUsableLength,
    partsPlaced,
    supports,
    allowEndJoinery,
    endJoineryLength,
  } = meta

  const start = isVertical ? span.yPosition : span.xPosition
  const end = start + span.length
  const spanLengthToCover = end - start

  let extraMaterialLengthsNeeded = 0
  let needNewMaterialLength = oldLengthAvailable < minimumUsableLength
  let currentLengthAvailable = needNewMaterialLength
    ? materialLength
    : oldLengthAvailable
  const maxLengthOfThisPart = Math.min(
    spanLengthToCover,
    currentLengthAvailable
  )

  let lengthOfThisPart: number
  if (maxLengthOfThisPart === spanLengthToCover || supports.length === 0) {
    extraMaterialLengthsNeeded = needNewMaterialLength ? 1 : 0
    lengthOfThisPart = maxLengthOfThisPart
  } else {
    const propsForFindingBestPart = {
      isVertical,
      start,
      supports,
      allowEndJoinery,
      endJoineryLength,
    }

    lengthOfThisPart = findBestPartLengthBasedOnSupports({
      ...propsForFindingBestPart,
      maximumPartLength: maxLengthOfThisPart,
    })

    // not enough material length to make the next support OR
    // the furthest covered support is closer than the minimum available length
    if (lengthOfThisPart < minimumUsableLength) {
      needNewMaterialLength = true
      currentLengthAvailable = materialLength
      const maxLengthOfThisPart = Math.min(
        spanLengthToCover,
        currentLengthAvailable
      )

      lengthOfThisPart = findBestPartLengthBasedOnSupports({
        ...propsForFindingBestPart,
        maximumPartLength: maxLengthOfThisPart,
      })
    }

    extraMaterialLengthsNeeded = needNewMaterialLength ? 1 : 0
  }

  const newPartPlaced = {
    ...span,
    xPosition: span.xPosition,
    yPosition: span.yPosition,
    length: lengthOfThisPart,
  }

  const nextSpanToCover = {
    ...span,
    xPosition: isVertical ? span.xPosition : span.xPosition + lengthOfThisPart,
    yPosition: isVertical ? span.yPosition + lengthOfThisPart : span.yPosition,
    length: spanLengthToCover - lengthOfThisPart,
  }

  let newMaterialLengthAvailable = currentLengthAvailable - lengthOfThisPart
  if (
    newMaterialLengthAvailable < minimumUsableLength &&
    nextSpanToCover.length > 0
  ) {
    extraMaterialLengthsNeeded++
    newMaterialLengthAvailable = materialLength
  }

  const newMetadata = {
    ...meta,
    materialLengthAvailable: newMaterialLengthAvailable,
    materialQuantityUsed: materialQuantityUsed + extraMaterialLengthsNeeded,
    partsPlaced: [...partsPlaced, newPartPlaced],
  }

  if (nextSpanToCover.length === 0) {
    return newMetadata
  } else {
    return placePartForSpan(nextSpanToCover, newMetadata)
  }
}

export const placePartsForSpans = (
  spansToCover,
  meta: Partial<PlacePartsRecursivelyMeta>
) => {
  const metaWithDefaults = Object.assign(
    {},
    {
      material: meta.material,
      isVertical: !!meta.isVertical,
      materialQuantityUsed: 0,
      materialLengthAvailable: 0,
      materialLength: meta.material.length,
      minimumUsableLength: 500,
      partsPlaced: [],
      supports: [],
      allowEndJoinery: true,
      endJoineryLength: 200,
    },
    meta
  )

  if (spansToCover.length === 0) return metaWithDefaults

  const [spanToCover, ...restOfSpansToCover] = spansToCover
  const newMetadata = placePartForSpan(spanToCover, metaWithDefaults)

  if (restOfSpansToCover.length > 0) {
    return placePartsForSpans(restOfSpansToCover, {
      ...newMetadata,
      material: meta.material,
    })
  } else {
    return { ...newMetadata, material: meta.material }
  }
}

export default placePartsForSpans
