import { Material } from "../types"

export const DeckingBoard: Material = {
  name: "Decking board",
  description: "Machined plank with grooved top surface to stop slipping",
  price: 22.5,
  color: "black",
  width: 150,
  height: 27,
  length: 3600,
  weight: 13,

  endSpacing: 3,
  edgeSpacing: 2,
}

export const Joist: Material = {
  name: "Joist",
  description: "Thick plank which supports the decking boards",
  price: 22.5,
  color: "black",
  width: 50,
  height: 100,
  length: 3600,
  weight: 13,

  endSpacing: 3,
  edgeSpacing: 0,
}

export const Beam: Material = {
  name: "Beam",
  description:
    "Thick plank which spans between the posts and supports the joists",
  price: 22.5,
  color: "black",
  width: 50,
  height: 100,
  length: 3600,
  weight: 13,

  endSpacing: 3,
  edgeSpacing: 0,
}

export const Post: Material = {
  name: "Post",
  description:
    "Long, thick section which is sunk into the ground to support the deck",
  price: 22.5,
  color: "black",
  width: 80,
  height: 80,
  length: 3000,
  weight: 13,
}
