import { RootState } from "redux/createStore"
import { BillOfMaterialsItem, DeckingSpecification } from "types"
import { omit } from "lodash/fp"
import { Plan } from "types/firebase"
import createReduxHelpersForCollection, {
  CollectionState,
} from "infrastructure/reduxCollection"

const collectionName = "plans"

const {
  reducer,
  fetchActionCreator,
  fetchErrorActionCreator,
  createActionCreator,
  patchActionCreator,
  selectAllDocuments,
  selectDocumentById,
  selectDocumentStateById,
} = createReduxHelpersForCollection<Plan>(collectionName)

export type PlanReducerState = CollectionState<Plan>

const buildPlanFromSpecification = ({
  name,
  merchantId,
  bom,
  spec,
}: {
  name: string
  merchantId: string
  spec: DeckingSpecification
  bom: BillOfMaterialsItem[]
}) => ({
  name,
  merchantId,
  orderedAt: null,
  specification: omit(["fixings", "materials", "visibility"])(spec),
  fixings: bom ? spec.fixings : null,
  materials: bom ? spec.materials : null,
  billOfMaterials: bom || null,
})

export const fetchPlan =
  (planId: string) =>
  async (dispatch, getState, { db }) => {
    const planRef = db.collection("plans").doc(planId)

    return await planRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          const plan = {
            id: doc.id,
            ...doc.data(),
          }

          dispatch(fetchActionCreator([plan]))
          return plan
        } else {
          dispatch(
            fetchErrorActionCreator(planId, {
              status: 404,
              code: "storage/object-not-found",
              name: "Not Found",
              message: "Could not find this plan",
            })
          )
          return null
        }
      })
      .catch((err) => {
        dispatch(
          fetchErrorActionCreator(planId, {
            status: err.statusText,
            name: err.name,
            message: err.message,
            code: err.code,
          })
        )
        throw err
      })
  }

export const createPlan =
  ({
    name,
    spec,
    bom,
  }: {
    name: string
    spec: DeckingSpecification
    bom?: BillOfMaterialsItem[]
  }) =>
  (dispatch, getState, { db }) => {
    const state = getState() as RootState
    const merchantId = state.appSettings.merchantId
    const authId = state.auth?.authUser?.uid
    const merchantRef = db.collection("merchants").doc(merchantId)
    const plan = buildPlanFromSpecification({
      name,
      merchantId,
      spec,
      bom,
    })

    const planToSave = {
      authId: authId,
      merchantId,
      merchant: merchantRef,
      ...plan,
    } as Plan

    const doc = db
      .collection("plans")
      .add(planToSave)
      .then((doc) => {
        const planWithId = { id: doc.id, ...planToSave }
        dispatch(createActionCreator(planWithId))
        return planWithId
      })
      .catch((err) => {
        const apiError = {
          status: err.statusText,
          name: err.name,
          message: err.message,
          code: err.code,
        }

        dispatch(
          fetchErrorActionCreator("new-plan", {
            status: err.statusText,
            name: err.name,
            message: err.message,
            code: err.code,
          })
        )

        throw apiError
      })

    return doc
  }

export {
  reducer,
  selectAllDocuments,
  selectDocumentById,
  selectDocumentStateById,
}
