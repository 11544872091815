import React, { useState } from "react"
import { useLocation, useHistory } from "react-router-dom"
import styled from "styled-components"
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome"
import {
  faPlus,
  faBars,
  faBook,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons"
import {
  Nav,
  NavItem,
  NavItemIcon,
  NavItemText,
} from "@zendeskgarden/react-chrome"

import useFirebaseAuth from "infrastructure/useFirebaseAuth"

const NavigationHeader = styled.h1`
  padding: ${({ theme }) => theme.space.md};
`

const BottomAlignedNavItem = styled(NavItem)`
  margin-top: auto;
`

const NavLink = ({
  icon,
  path,
  label,
  disabled = false,
  expanded,
}: {
  icon: React.ReactElement
  label: string
  path: string
  disabled?: boolean
  expanded?: boolean
}) => {
  const location = useLocation()
  const history = useHistory()

  return (
    <NavItem
      disabled={disabled}
      isCurrent={path === location.pathname}
      onClick={() => {
        if (!disabled) {
          history.push(path)
        }
      }}
    >
      <NavItemIcon title={label}>{icon}</NavItemIcon>
      {expanded && <NavItemText>{label}</NavItemText>}
    </NavItem>
  )
}

const Navigation = () => {
  const { authUser, logout } = useFirebaseAuth()
  const [isExpanded, setIsExpanded] = useState(true)

  return (
    <Nav isExpanded={isExpanded}>
      <NavigationHeader onClick={() => setIsExpanded(!isExpanded)}>
        {isExpanded ? "Decking Calculator" : <Icon icon={faBars} size={"lg"} />}
      </NavigationHeader>
      <NavLink
        icon={<Icon icon={faPlus} size={"lg"} />}
        label={"Create new plan"}
        path="/plans/new"
        expanded={isExpanded}
      />
      <NavLink
        icon={<Icon icon={faBook} size={"lg"} />}
        label={"Saved plans"}
        path="/plans"
        disabled={!authUser}
        expanded={isExpanded}
      />
      {authUser && (
        <BottomAlignedNavItem onClick={() => logout()}>
          <NavItemIcon>
            <Icon icon={faExternalLinkAlt} size="lg" />
          </NavItemIcon>
          <NavItemText>Logout</NavItemText>
        </BottomAlignedNavItem>
      )}
    </Nav>
  )
}

export default Navigation
