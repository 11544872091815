import { Direction, DiagramFeature, PartType, FixingType } from "./enums"

export interface Fixing {
  name: string
  description: string
  sizeDescription: string
  price: number
  quantity: number
}

export interface Material {
  name: string
  description: string
  color: string
  price: number
  length: number
  width: number
  height: number
  weight: number

  endSpacing?: number
  edgeSpacing?: number
}

export interface BillOfMaterialsItem {
  name?: string
  description?: string
  material?: Material
  fixing?: Fixing
  color?: string
  usedQuantity?: number
  purchaseQuantity: number
  totalPurchasePrice: number
}

export interface FixingSpecification {
  fixing: Fixing
  quantity: number
  spacing?: number
}

export type Visibility = Partial<Record<PartType | DiagramFeature, boolean>>

export interface BaseSpecification {
  width: number
  length: number
  height: number
  boardDirection: Direction

  maxJoistSpacing: number
  maxPostSpacing: number
  maxNogginSpacing: number

  spaceBetweenDeckingBoardLength: number
  spaceBetweenDeckingBoardWidths: number

  addRimJoists: boolean
  addLedgerBoards: boolean
  pairJoistsAroundPosts: boolean
  pairJoistsAroundExteriorPosts: boolean
  allowEndJoinery: boolean

  addBeams: boolean
  pairBeamsAroundPosts: boolean
  pairBeamsAroundExteriorPosts: boolean

  addFascias: {
    top: boolean
    left: boolean
    right: boolean
    bottom: boolean
  }
}

export interface DeckingSpecification extends BaseSpecification {
  materials: {
    [PartType.Joist]: Material
    [PartType.DeckingBoard]: Material
    [PartType.Post]: Material
  }
  fixings: Record<FixingType, Array<FixingSpecification>>
  visibility: Visibility
}
