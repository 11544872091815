import { DiagramFeature, Visibility, PartType } from "types"
import { reduce } from "lodash/fp"

const diagramFeatures = Object.keys(DiagramFeature) as DiagramFeature[]
const partTypes = Object.keys(PartType) as PartType[]

const setAll = (value: boolean) => (
  array: Array<DiagramFeature | PartType>
): Visibility =>
  reduce((out, key) => {
    out[key] = value
    return out
  }, {})(array)

export const allPartsVisible = setAll(true)(partTypes)
export const allPartsHidden = setAll(false)(partTypes)
export const allFeaturesVisible = setAll(true)(diagramFeatures)
export const allFeaturesHidden = setAll(false)(diagramFeatures)
