import {
  BillOfMaterialsItem,
  DeckingSpecification,
  FixingRequirement,
  FixingSpecification,
  FixingType,
} from "types"
import { groupBy, flatten, map, pipe, sumBy, toPairs } from "lodash/fp"

export const getFixingBomItemsFromRequirements = (
  requirements: Array<FixingRequirement>,
  spec: DeckingSpecification
): Array<BillOfMaterialsItem> => {
  return pipe(
    map(({ fixingType, quantity: numberOfJoins }: FixingRequirement) => {
      const fixingsForType = spec.fixings[fixingType] || []
      return fixingsForType.map(
        ({ fixing, quantity: quantityPerJoin }: FixingSpecification) => {
          return {
            key: fixing.name + fixing.sizeDescription + fixing.quantity,
            fixing,
            quantity: numberOfJoins * quantityPerJoin,
          }
        }
      )
    }),
    flatten,
    groupBy("key"),
    toPairs,
    map(([key, fixingTotals]): BillOfMaterialsItem => {
      const fixing = fixingTotals[0].fixing
      const count = sumBy("quantity")(fixingTotals)
      const totalQuantity = Math.ceil(
        sumBy("quantity")(fixingTotals) / fixing.quantity
      )
      return {
        description: fixing.name,
        fixing,
        usedQuantity: count,
        purchaseQuantity: totalQuantity,
        totalPurchasePrice: totalQuantity * fixing.price,
      }
    })
  )(requirements)
}

export const addFixingRequirements = (
  existingFixingRequirements: Array<FixingRequirement>,
  newFixingRequirements: Array<FixingRequirement>
) => {
  return pipe(
    groupBy("fixingType"),
    toPairs,
    map(
      ([fixingType, fixingRequirements]: [
        FixingType,
        Array<FixingRequirement>
      ]) => ({
        fixingType,
        quantity: sumBy("quantity")(fixingRequirements),
      })
    )
  )([...existingFixingRequirements, ...newFixingRequirements])
}
