import { Redirect, Route, Switch } from "react-router-dom"
import PrivateRoute from "components/PrivateRoute"

import Planner from "./planner"

const RedirectToPlanner = () => {
  return <Redirect to="/plans/new" />
}

const MainRouter = () => {
  return (
    <Switch>
      <Route path="/plans/new" exact component={Planner} />
      <Route path="/plans/:planId" component={Planner} />
      <Route path="/plans" exact component={Planner} />
      <Route path="/" exact component={RedirectToPlanner} />
    </Switch>
  )
}

export default MainRouter
