import React from "react"
import { BrowserRouter } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import { Provider as AppSettingsProvider } from "infrastructure/appSettingsContext"
import BaseApp from "components/BaseApp"
import MainRouter from "routes/router"
import createStore from "redux/createStore"
import { AppSettings } from "types"

type AppProps = {
  appSettings: AppSettings
  auth: any
  db: any
  GoogleAuthProvider: any
}

const App = ({ appSettings, auth, db, GoogleAuthProvider }: AppProps) => {
  const { store } = createStore({
    initialState: {
      appSettings: { merchantId: appSettings.merchantId },
    },
    auth,
    db,
    GoogleAuthProvider,
  })

  return (
    <React.StrictMode>
      <AppSettingsProvider value={appSettings}>
        <BrowserRouter basename={appSettings.baseUrl}>
          <BaseApp store={store} auth={auth}>
            <Helmet>
              <title>Decking Calculator</title>
              <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <MainRouter />
          </BaseApp>
        </BrowserRouter>
      </AppSettingsProvider>
    </React.StrictMode>
  )
}

export default App
