import { Fixing } from "../types"

export const CoachScrew: Fixing = {
  name: "Coach Screw",
  sizeDescription: "M8x150mm",
  description: "Coach Screws Yellow Zinc-Plated M8x150mm (50 Pack)",
  price: 14.99,
  quantity: 50,
}

export const Screw: Fixing = {
  name: "Screws",
  sizeDescription: "M4.5x65mm",
  description:
    "Double-Countersunk Carbon Steel Decking Screws M4.5x65mm (500 Pack)",
  price: 19.99,
  quantity: 500,
}

export const CoachBolt: Fixing = {
  name: "Coach Bolts",
  sizeDescription: "M10x150mm",
  description:
    "Threaded Coach Bolts Carbon Steel Bright Zinc-Plated M10x150mm (10 Pack)",
  price: 22.29,
  quantity: 10,
}

export const NailPlate: Fixing = {
  name: "Nail Plates",
  sizeDescription: "150mm x 75mm",
  description: "Sabrefix Hand Nail Plate Galvanised 150mm x 75mm (25pack)",
  price: 9.99,
  quantity: 25,
}

export const Nail: Fixing = {
  name: "Nails",
  sizeDescription: "2.65x40mm",
  description: "Round Wire Galvanised Nail 2.65x40mm (1kg Pack)",
  price: 5.31,
  quantity: 500,
}

export const Postcrete: Fixing = {
  name: "Postcrete",
  sizeDescription: "20kg bag",
  description: "Blue Circle Ready mixed Postcrete (20kg bag)",
  price: 4.82,
  quantity: 1,
}
