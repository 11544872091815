const UPDATE_APP_SETTINGS = "appSettings/update"

export type AppSettingsState = {
  merchantId: string
}

const updateAppSettings = (appSettings: { merchantId: string }) => ({
  type: UPDATE_APP_SETTINGS,
  payload: appSettings,
})

const getInitialState = (): AppSettingsState => {
  return {
    merchantId: null,
  }
}

const reducer = (
  state: AppSettingsState = getInitialState(),
  action
): AppSettingsState => {
  switch (action.type) {
    case UPDATE_APP_SETTINGS: {
      return { ...action.payload }
    }
  }

  return state
}

export { reducer, updateAppSettings }
